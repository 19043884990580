@import '../../styles/variables';

.load-screen {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(227, 230, 233, 0.52);
  z-index: 10000;

  > span {
    width: 48px !important;
    height: 48px !important;
  }
}
