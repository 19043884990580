.accordion-wrapper {
  border-radius: 4px;
}

.accordion-card {
  border-left: 4px solid #9db668;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 1px 0 5px 0 rgba(0, 0, 0, 0.2);
  .accordion-content {
    background: white;
  }
  .accordion-title {
    color: #444444;
    background: white;
  }
  .accordion-item {
    border: none;
  }
}

.accordion-mobile {
  border-radius: 0;

  .accordion-content {
    padding: 1em 8px;
  }

  .accordion-item,
  .accordion-title,
  .accordion-title.open {
    border-radius: 0;
  }
}

.accordion-item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
  border: solid 1px #ccc;
  border-radius: 0 0 4px 4px;
}

.accordion-content {
  background: #f9f9f9;
}

.accordion-item.collapsed {
  max-height: 0;
  border: none;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordion-title {
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #9db668;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  transition: 0.3s;

  &.open {
    border-radius: 4px 4px 0 0;
  }

  &-icon {
    > svg {
      transition: rotation 0.5s linear;
    }
    justify-content: flex-end;
    display: flex;
    flex: 1;
  }

  &-icon.open {
    > svg {
      transform: rotate(180deg);
    }
  }
}

.accordion-content {
  padding: 1em 1.5em;
}
