.table-print__container {
  table,
  th,
  td {
    border: 1px solid #bbbbbb;
    border-collapse: collapse;
    font-size: 12px;
  }

  th,
  td {
    padding: 8px;
  }

  th {
    background: #9db668;
    color: #ffffff;
    text-transform: uppercase;
    text-align: left;
  }
}
